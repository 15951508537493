import React from 'react';
import theme from 'theme';
import { Theme, Button, Link, Section, Text, Box } from '@quarkly/widgets';
import { Helmet } from 'react-helmet';
import { GlobalQuarklyPageStyles } from 'global-page-styles';
import { Override } from '@quarkly/components';

const details = [
  {
    title: 'Reading',
    subtitle:
      'We will determine your reading level based on your responses to words shown on the screen',
    background:
      '--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-first.svg?v=2020-11-06T16:37:39.391Z) center center/100% no-repeat',
    backgroundHover:
      '--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-first.svg?v=2020-11-06T16:37:39.391Z) center center/110% no-repeat'
  },
  {
    title: 'Spelling',
    subtitle:
      'We will determine your ability to correctly spell words that you know how to read (eidetic spelling) and your ability to spell words based on how they sound (phonetic spelling)',
    background:
      '--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-second.svg?v=2020-11-06T17:14:59.136Z) center center/100% no-repeat',
    backgroundHover:
      '--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-second.svg?v=2020-11-06T17:14:59.136Z) center center/110% no-repeat'
  },
  {
    title: 'Results',
    subtitle: 'We will discuss our findings and recommend a course of action',
    background:
      '--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-third.svg?v=2020-11-06T17:15:50.700Z) center center/100% no-repeat',
    backgroundHover:
      '--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-third.svg?v=2020-11-06T17:15:50.700Z) center center/110% no-repeat'
  }
];

const AdvanceButtonsDesktop = ({ detailsIdx, setDetailsIdx, disableNextButton = false }) => {
  return (
    <>
      <Button
        width="150px"
        margin="0px auto 0px 0px"
        color="--lightL2"
        transition="opacity .15s ease 0s"
        z-index="5"
        active-box-shadow="none"
        background="--color-primary"
        opacity="1"
        md-font="--base"
        padding="8px 36px 8px 36px"
        letter-spacing="0.5px"
        position="relative"
        hover-box-shadow="none"
        font="--lead"
        hover-opacity=".85"
        focus-box-shadow="none"
        disabled={detailsIdx === 0}
        pointer-events={detailsIdx === 0 ? 'none' : 'auto'}
        onClick={() => setDetailsIdx(detailsIdx - 1)}
      >
        Previous
      </Button>
      <Button
        width="150px"
        margin="0px auto auto calc(100% - 150px)"
        color="--lightL2"
        transition="opacity .15s ease 0s"
        z-index="5"
        active-box-shadow="none"
        background="--color-primary"
        opacity="1"
        md-font="--base"
        padding="8px 36px 8px 36px"
        letter-spacing="0.5px"
        position="relative"
        hover-box-shadow="none"
        font="--lead"
        hover-opacity=".85"
        focus-box-shadow="none"
        disabled={disableNextButton}
        pointer-events={disableNextButton ? 'none' : 'auto'}
        onClick={() => setDetailsIdx(detailsIdx + 1)}
      >
        Next
      </Button>
    </>
  );
};

const AdvanceButtonsMobile = ({ detailsIdx, setDetailsIdx, disableNextButton = false }) => {
  return (
    <Box
      min-width="150px"
      min-height="100px"
      display="flex"
      flex-direction="row"
      align-items="center"
      justify-content="spaced-evenly"
    >
      <Button
        width="150px"
        margin="0px auto 0px 0px"
        color="--lightL2"
        transition="opacity .15s ease 0s"
        z-index="5"
        active-box-shadow="none"
        background="--color-primary"
        opacity="1"
        md-font="--base"
        padding="8px 36px 8px 36px"
        letter-spacing="0.5px"
        position="relative"
        hover-box-shadow="none"
        font="--lead"
        hover-opacity=".85"
        focus-box-shadow="none"
        disabled={detailsIdx === 0}
        pointer-events={detailsIdx === 0 ? 'none' : 'auto'}
        onClick={() => setDetailsIdx(detailsIdx - 1)}
      >
        Previous
      </Button>
      <Button
        width="150px"
        margin="0px auto 0px calc(100%-150px)"
        color="--lightL2"
        transition="opacity .15s ease 0s"
        z-index="5"
        active-box-shadow="none"
        background="--color-primary"
        opacity="1"
        md-font="--base"
        padding="8px 36px 8px 36px"
        letter-spacing="0.5px"
        position="relative"
        hover-box-shadow="none"
        font="--lead"
        hover-opacity=".85"
        focus-box-shadow="none"
        disabled={disableNextButton}
        pointer-events={disableNextButton ? 'none' : 'auto'}
        onClick={() => setDetailsIdx(detailsIdx + 1)}
      >
        Next
      </Button>
    </Box>
  );
};

const InfoPanels = ({
  title,
  subtitle,
  background,
  backgroundHover,
  mobileView,
  detailsIdx,
  setDetailsIdx
}) => {
  return (
    <Section
      lg-padding="30px 0 30px 0"
      quarkly-title="Info"
      justify-content="center"
      padding="30px 0 30px 0"
      sm-padding="10px 0 10px 0"
    >
      <Override
        slot="SectionContent"
        width="100%"
        md-margin="0px 16px 0px 16px"
        min-width="auto"
        margin="0px 32px 0px 32px"
      />
      <Box
        grid-gap="32px"
        grid-template-columns="2fr 3fr"
        lg-grid-template-columns="1fr"
        display="grid"
      >
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          flex-direction="column"
          align-items="flex-start"
        >
          <Text
            color="--dark"
            lg-text-align="center"
            md-margin="0px 0px 16px 0px"
            md-font="--headline3"
            font="--headline2"
            margin="0px 0px 28px 0px"
          >
            {title}
          </Text>
          <Text
            md-font="--base"
            font="--lead"
            margin="0px 0px 20px 0px"
            color="--darkL2"
            opacity="0.6"
            lg-text-align="left"
          >
            {subtitle}
          </Text>
        </Box>

        <Box
          transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
          lg-order="-1"
          width="100%"
          background={background}
          margin="0px 0px 0px 0px"
          padding="0px 0px 60% 0px"
          height="0px"
          hover-background={backgroundHover}
        />
        {mobileView ? (
          <AdvanceButtonsMobile detailsIdx={detailsIdx} setDetailsIdx={setDetailsIdx} />
        ) : (
          <AdvanceButtonsDesktop detailsIdx={detailsIdx} setDetailsIdx={setDetailsIdx} />
        )}
      </Box>
    </Section>
  );
};

const PrivacyPolicyScreen = ({
  didAckPrivacyPolicy,
  setDidAckPrivacyPolicy,
  background,
  backgroundHover,
  mobileView,
  detailsIdx,
  setDetailsIdx
}) => {
  return (
    <Section
      lg-padding="30px 0 30px 0"
      quarkly-title="Info"
      justify-content="center"
      padding="30px 0 30px 0"
      sm-padding="10px 0 10px 0"
    >
      <Override
        slot="SectionContent"
        width="100%"
        md-margin="0px 16px 0px 16px"
        min-width="auto"
        margin="0px 32px 0px 32px"
      />
      <Box
        grid-gap="32px"
        grid-template-columns="2fr 3fr"
        lg-grid-template-columns="1fr"
        display="grid"
      >
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          flex-direction="column"
          align-items="flex-start"
        >
          <Text
            color="--dark"
            lg-text-align="center"
            md-margin="0px 0px 16px 0px"
            md-font="--headline3"
            font="--headline2"
            margin="0px 0px 28px 0px"
          >
            We take your privacy seriously.
          </Text>
          <Text
            display="inline-block"
            opacity="0.6"
            color="--darkL2"
            lg-text-align="left"
            text-align="left"
            font="--base"
            margin="0px 0px 0px 0px"
          >
            <input
              type="checkbox"
              onClick={() => setDidAckPrivacyPolicy(!didAckPrivacyPolicy)}
            ></input>
            Check this box to confirm you have read <Link href="#">Gryfn's privacy policy</Link> and
            that you understand Gryfn will use your health data to calculate vision results.
          </Text>
        </Box>

        <Box
          transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
          lg-order="-1"
          width="100%"
          background={background}
          margin="0px 0px 0px 0px"
          padding="0px 0px 60% 0px"
          height="0px"
          hover-background={backgroundHover}
        />
        {/* Disable the next button until the privacy policy is acknowledged */}
        {mobileView ? (
          <AdvanceButtonsMobile
            detailsIdx={detailsIdx}
            setDetailsIdx={setDetailsIdx}
            disableNextButton={!didAckPrivacyPolicy}
          />
        ) : (
          <AdvanceButtonsDesktop
            detailsIdx={detailsIdx}
            setDetailsIdx={setDetailsIdx}
            disableNextButton={!didAckPrivacyPolicy}
          />
        )}
      </Box>
    </Section>
  );
};

const GetStartedScreen = ({ setAdvanceToTestScreen }) => {
  return (
    <Section
      padding="75px 0 50px 0"
      lg-padding="50px 0 25px 0"
      lg-height="auto"
      justify-content="center"
      quarkly-title="USP"
    >
      <Override
        slot="SectionContent"
        width="100%"
        margin="0px 32px 0px 32px"
        md-margin="0px 16px 0px 16px"
        min-width="auto"
      />
      <Box
        flex-direction="column"
        lg-min-height="420px"
        sm-min-height="280px"
        min-height="480px"
        padding="36px 24px 36px 24px"
        display="flex"
        align-items="center"
        justify-content="center"
        background="linear-gradient(180deg,rgba(24, 29, 34, 0.5) 0%,transparent 100%) 0 0 no-repeat,--color-lightD1 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-fireworks.svg?v=2020-11-06T17:22:27.801Z) center center/cover no-repeat"
        md-min-height="360px"
      >
        <Text
          font="--headline2"
          text-align="center"
          quarkly-title="Title"
          color="--light"
          md-font="--headline3"
          margin="10px 0px 15px 0px"
        >
          Online DESD
        </Text>
        <Text
          margin="0px 0px 28px 0px"
          font="--lead"
          opacity="0.6"
          text-align="center"
          quarkly-title="Description"
          color="--light"
          max-width="720px"
        >
          The DESD is a screening test that allows for assessment of a student's specific reading
          difficulties and whether the student is at risk for dyslexia. The test identifies the
          specific skills that a child brings to bear on the task of reading words. It consists of
          three sections: Decoding, Eidetic Encoding and Phonetic Encoding.
          <br />
          <br />
          The Decoding section provides a measure of sight-word recognition (Reading Standard
          Score). Indicators in the Encoding section allow the examiner to distinguish deficits in
          sight-word recognition from deficits in phonetic analysis. This makes it easier to detect
          and describe reading problems and to refer students for appropriate educational therapy
        </Text>
        <Button
          margin="10px auto 0px auto"
          width="fit-content"
          color="--darkL2"
          transition="opacity .15s ease 0s"
          z-index="5"
          active-box-shadow="none"
          background="--color-light"
          opacity="1"
          md-font="--base"
          padding="8px 36px 8px 36px"
          letter-spacing="0.5px"
          position="relative"
          hover-box-shadow="none"
          font="--lead"
          hover-opacity=".85"
          focus-box-shadow="none"
          onClick={() => setAdvanceToTestScreen(true)}
        >
          Begin the Test
        </Button>
      </Box>
    </Section>
  );
};

export const Work = ({ setAdvanceToTestScreen, didAckPrivacyPolicy, setDidAckPrivacyPolicy }) => {
  // bool indicating if device should be shown for mobile
  const [mobileView] = React.useState(window.matchMedia('(max-width: 990px)').matches);
  const [detailsIdx, setDetailsIdx] = React.useState(0);

  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={'work'} />
      <Helmet>
        <title>Gryfn Dyslexia Test</title>
        <meta
          name={'description'}
          content={
            'Comprehensive dyslexia screening and personalized support for reading and learning success.'
          }
        />
        <meta property={'og:title'} content={'Gryfn Dyslexia Test'} />
        <meta
          property={'og:description'}
          content={
            'Comprehensive dyslexia screening and personalized support for reading and learning success.'
          }
        />
        <meta
          property={'og:image'}
          content={
            'https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z'
          }
        />
        <link
          rel={'shortcut icon'}
          href={'https://uploads.quarkly.io/readme/cra/favicon-32x32.ico'}
          type={'image/x-icon'}
        />
      </Helmet>
      {/* Only show the screen header until we begin the test*/}
      {detailsIdx <= details.length ? (
        <Section
          padding="25px 0 25px 0"
          lg-padding="25px 0 25px 0"
          justify-content="center"
          quarkly-title="Hero"
        >
          <Override
            slot="SectionContent"
            width="100%"
            margin="0px 32px 0px 12px"
            md-margin="0px 16px 0px 16px"
            min-width="auto"
          />
          <Box
            min-height="180px"
            width="100%"
            display="flex"
            align-items="center"
            justify-content="center"
            background="radial-gradient(at center,--color-darkL2 23.3%,rgba(0,0,0,0) 82.4%),linear-gradient(180deg,rgba(155, 108, 252, 0.15) 0%,transparent 100%) 0 0 no-repeat,--color-darkL2"
          >
            <Text
              justify-content="center"
              quarkly-title="Title"
              text-align="center"
              sm-text-align="center"
              md-font="--headline3"
              font="--headline2"
              margin="0px 0px 0px 0px"
              display="flex"
              align-items="center"
              color="--light"
              sm-font="normal 900 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
              lg-font="--headline3"
            >
              How the online DESD works
            </Text>
          </Box>
        </Section>
      ) : null}
      {
        // once we get to the end of the info panels
        // we show the privacy policy screen
        detailsIdx < details.length ? (
          <InfoPanels
            title={details[detailsIdx].title}
            subtitle={details[detailsIdx].subtitle}
            background={details[detailsIdx].background}
            backgroundHover={details[detailsIdx].backgroundHover}
            mobileView={mobileView}
            detailsIdx={detailsIdx}
            setDetailsIdx={setDetailsIdx}
          />
        ) : detailsIdx === details.length ? (
          <PrivacyPolicyScreen
            didAckPrivacyPolicy={didAckPrivacyPolicy}
            setDidAckPrivacyPolicy={setDidAckPrivacyPolicy}
            background={details[detailsIdx - 1].background}
            backgroundHover={details[detailsIdx - 1].backgroundHover}
            mobileView={mobileView}
            detailsIdx={detailsIdx}
            setDetailsIdx={setDetailsIdx}
          />
        ) : (
          <GetStartedScreen setAdvanceToTestScreen={setAdvanceToTestScreen} />
        )
      }
    </Theme>
  );
};

export default Work;
