import React from 'react';
import Index from 'pages/index';
import About from 'pages/about';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }
`;

export default () => (
  <Router>
    <GlobalStyles />
    <Switch>
      {<Route exact path={'/speech'} component={About} />}
      <Route component={Index} />
    </Switch>
  </Router>
);
