import React from 'react';
import theme from 'theme';
import { Theme, Link, Section, Text } from '@quarkly/widgets';
import { Helmet } from 'react-helmet';
import { GlobalQuarklyPageStyles } from 'global-page-styles';
import { Override } from '@quarkly/components';
import Work from './work.js';
import Team from './team.js';
import About from './about.js';

const Home = ({ setIsReady }) => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={'index'} />
      <Helmet>
        <title>Gryfn Dyslexia Test</title>
        <meta
          name={'description'}
          content={
            'Comprehensive dyslexia screening and personalized support for reading and learning success.'
          }
        />
        <meta property={'og:title'} content={'Home | Gryfn Dyslexia Test'} />
        <meta
          property={'og:description'}
          content={
            'Comprehensive dyslexia screening and personalized support for reading and learning success.'
          }
        />
        <meta
          property={'og:image'}
          content={
            'https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z'
          }
        />
        <link
          rel={'shortcut icon'}
          href={'https://uploads.quarkly.io/readme/cra/favicon-32x32.ico'}
          type={'image/x-icon'}
        />
      </Helmet>
      <Section
        lg-padding="25px 0 25px 0"
        sm-padding="25px 0 25px 0"
        justify-content="center"
        padding="25px 0 75px 0"
        sm-align-items="center"
        sm-justify-content="center"
        quarkly-title="Hero"
      >
        <Override
          slot="SectionContent"
          md-margin="0px 16px 0px 16px"
          width="100%"
          background="linear-gradient(180deg,rgba(155, 108, 252, 0.15) 0%,transparent 100%) 0 0 no-repeat,#191C23 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-works-angle.svg?v=2020-11-06T16:36:54.345Z) center center/cover repeat scroll padding-box"
          height="620px"
          justify-content="center"
          sm-width="100%"
          min-width="auto"
          margin="0px 32px 0px 32px"
          align-items="center"
          lg-height="820px"
          md-height="720px"
          md-padding="0px 24px 0px 24px"
        />
        <Text
          font="--headline1"
          margin="16px 0px 0px 0px"
          sm-text-align="center"
          sm-width="80%"
          lg-text-align="center"
          lg-font="--headline2"
          color="--light"
        >
          Test for Dyslexia
        </Text>
        <Text
          sm-text-align="center"
          sm-width="80%"
          opacity="0.7"
          md-text-align="center"
          font="--lead"
          color="--light"
          lg-margin-left="20px"
          lg-margin-right="20px"
        >
          Comprehensive dyslexia screening and personalized support for reading and learning
          success.
        </Text>
        <Link
          text-decoration-line="initial"
          color="--darkL2"
          padding="12px 24px 12px 24px"
          letter-spacing="0.5px"
          transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
          background="--color-light"
          font="--base"
          hover-transform="translateY(-4px)"
          onClick={setIsReady}
        >
          Get Started
        </Link>
        <Text
          sm-text-align="center"
          sm-width="80%"
          opacity="0.7"
          md-text-align="center"
          font="--lead"
          color="--light"
          margin="10px 0px 35px 0px"
        ></Text>
        <Text
          sm-text-align="center"
          sm-width="80%"
          opacity="0.7"
          md-text-align="center"
          font="--lead"
          color="--light"
          margin="10px 0px 35px 0px"
        >
          Online test | Immediate results | Personalized support
        </Text>
      </Section>
    </Theme>
  );
};

export default () => {
  const [isInfoScreenReady, setIsInfoScreenReady] = React.useState(false);
  const [isTestScreenReady, setIsTestScreenReady] = React.useState(false);
  const [didAckPrivacyPolicy, setDidAckPrivacyPolicy] = React.useState(false);
  const [isDecodeTestReady, setIsDecodeTestReady] = React.useState(false);

  if (!isInfoScreenReady) {
    return <Home setIsReady={setIsInfoScreenReady} />;
  }
  if (isInfoScreenReady && !isTestScreenReady && !isDecodeTestReady) {
    return (
      <Work
        didAckPrivacyPolicy={didAckPrivacyPolicy}
        setDidAckPrivacyPolicy={setDidAckPrivacyPolicy}
        setAdvanceToTestScreen={setIsTestScreenReady}
      />
    );
  }
  if (isTestScreenReady && didAckPrivacyPolicy && !isDecodeTestReady) {
    return <Team />;
  }

  if (isDecodeTestReady) {
    return <About />;
  }

  return <div>Hello world again lol</div>;
};
