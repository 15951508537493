import React from 'react';
import theme from 'theme';
import { Theme, Link, Image, Button, Section, Text, Box } from '@quarkly/widgets';
import { Helmet } from 'react-helmet';
import { GlobalQuarklyPageStyles } from 'global-page-styles';
import { Override } from '@quarkly/components';

const questions = [
  {
    question:
      'Have you received any special education services or received extra help with reading or learning in the past?',
    yesID: 'special-ed-yes',
    noID: 'special-ed-no'
  },
  {
    question:
      'Have you ever been diagnosed with any learning disabilities or neurological conditions?',
    yesID: 'learning-disabilities-yes',
    noID: 'learning-disabilities-no'
  },
  {
    question:
      'Have you been diagnosed or treated for any mental health conditions such as attention deficit hyperactivity disorder (ADHD) or anxiety?',
    yesID: 'mental-health-yes',
    noID: 'mental-health-no'
  },
  {
    question:
      'Are you currently taking any medications that could affect your ability to learn or process information?',
    yesID: 'medications-yes',
    noID: 'medications-no'
  },
  {
    question: 'Have you experienced any head injuries or concussions recently?',
    yesID: 'head-injuries-yes',
    noID: 'head-injuries-no'
  },
  {
    question: 'Have you recently had an eye examination?',
    yesID: 'eye-exam-yes',
    noID: 'eye-exam-no'
  }
];

const educationOptions = [
  {
    value: 'kindergarten',
    text: 'Kindergarten'
  },
  {
    value: 'first_grade',
    text: 'First Grade'
  },
  {
    value: 'second_grade',
    text: 'Second Grade'
  },
  {
    value: 'third_grade',
    text: 'Third Grade'
  },
  {
    value: 'fourth_grade',
    text: 'Fourth Grade'
  },
  {
    value: 'fifth_grade',
    text: 'Fifth Grade'
  },
  {
    value: 'sixth_grade',
    text: 'Sixth Grade'
  },
  {
    value: 'seventh_grade',
    text: 'Seventh Grade'
  },
  {
    value: 'eighth_grade',
    text: 'Eighth Grade'
  },
  {
    value: 'high_school',
    text: 'High School'
  },
  {
    value: 'two_year_college',
    text: 'Two Year College'
  },
  {
    value: 'four_year_college',
    text: 'Four Year College'
  },
  {
    value: 'masters_degree',
    text: "Master's Degree"
  },
  {
    value: 'doctorate',
    text: 'PhD/MD/JD/etc'
  },
  {
    value: 'post_doctorate',
    text: 'Post doctorate'
  }
];

const areAllQuestionsAnswered = () => {
  for (let i = 0; i < questions.length; i++) {
    const { yesID, noID } = questions[i];
    const yesIsChecked = document.getElementById(yesID)?.checked;
    const noIsChecked = document.getElementById(noID)?.checked;
    // if there exists one question which is not correctly selected
    // then all questions are not answered (so we return false)
    if (yesIsChecked && noIsChecked) return false;
    if (!(yesIsChecked || noIsChecked)) return false;
  }

  return true;
};

export const Team = ({ setIsDecodeTestReady }) => {
  const [mobileView] = React.useState(window.matchMedia('(max-width: 990px)').matches);
  const [showIdx, setShowIdx] = React.useState(0);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = React.useState(false);
  const [isBirthdateSet, setIsBirthdateSet] = React.useState(false);
  const [isGradeLevelSet, setIsGradeLevelSet] = React.useState(false);

  const validateCheckBox = ({ yesID, noID, callerIsYesBox, idx }) => {
    const yesIsChecked = document.getElementById(yesID).checked;
    const noIsChecked = document.getElementById(noID).checked;

    if (yesIsChecked && noIsChecked) {
      // we cannot have yes and no both checked
      // so uncheck the other box
      if (callerIsYesBox) document.getElementById(noID).checked = false;
      else document.getElementById(yesID).checked = false;
      return;
    }
    // if one box is selected correctly, then we track it in state
    if (!(yesIsChecked && noIsChecked) && (yesIsChecked || noIsChecked)) {
      // only increment when we need to
      if (idx + 1 > showIdx) {
        setShowIdx(idx + 1);
      }
    }

    // check if all questions are answered exactly once
    const areQsAnswered = areAllQuestionsAnswered();
    setAllQuestionsAnswered(areQsAnswered);
  };

  // auto scroll to the next box that appears on mobile
  // unless user has scrolled back up
  React.useEffect(() => {
    if (mobileView && showIdx > 0 && showIdx < questions.length && !allQuestionsAnswered) {
      const elHeight = document.getElementById(showIdx.toString()).offsetHeight * showIdx;
      window.scrollTo(0, elHeight);
    }
  }, [document, showIdx]);

  React.useEffect(() => {
    if (mobileView && allQuestionsAnswered && !isBirthdateSet) {
      const elHeight = document.getElementById('birthdate-box')?.offsetHeight;
      if (elHeight) {
        window.scrollTo(0, elHeight * (showIdx + 1)); // one more because birthday question is one past
      }
    }
  }, [allQuestionsAnswered]);

  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={'team'} />
      <Helmet>
        <title>Gryfn Dyslexia Test</title>
        <meta
          name={'description'}
          content={
            'Comprehensive dyslexia screening and personalized support for reading and learning success.'
          }
        />
        <link
          rel={'shortcut icon'}
          href={'https://uploads.quarkly.io/readme/cra/favicon-32x32.ico'}
          type={'image/x-icon'}
        />
      </Helmet>

      <Section
        lg-padding="50px 0px 50px 0px"
        justify-content="center"
        padding="80px 0px 60px 0px"
        box-sizing="border-box"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          width="100%"
          margin="0px 32px 0px 32px"
          md-margin="0px 16px 0px 16px"
          min-width="auto"
        />
        <Box
          sm-grid-template-columns="1fr"
          min-width="10px"
          display="grid"
          width="100%"
          grid-gap="32px"
          grid-template-columns="repeat(3, 1fr)"
          md-grid-template-columns="repeat(2, 1fr)"
        >
          {questions.map(({ question, yesID, noID }, idx) => {
            // we show a question one at a time
            // based on state keeping track of which index question was the last answered
            return (
              <Box
                id={idx.toString()}
                display={idx <= showIdx ? 'block' : 'none'}
                flex-direction="column"
                align-items="center"
              >
                <Box
                  padding="20px 0px 20px 0px"
                  height="230px"
                  hover-background="--color-lightD1 center center/110% no-repeat"
                  transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
                  background="--color-lightD1 center center/100% no-repeat"
                  width="100%"
                >
                  <Text
                    display="inline-block"
                    opacity="0.6"
                    color="--darkL2"
                    text-align="left"
                    font="--base"
                    margin="10px 10px 10px 10px"
                  >
                    {question}
                  </Text>
                  <Text display="flex" text-align="justify" margin-left="15px">
                    <input
                      type="checkbox"
                      id={yesID}
                      onClick={() => validateCheckBox({ yesID, noID, callerIsYesBox: true, idx })}
                    ></input>
                    <label htmlFor={yesID}>Yes</label>
                  </Text>

                  <Text display="flex" margin-left="15px" text-align="justify">
                    <input
                      type="checkbox"
                      id={noID}
                      onClick={() => validateCheckBox({ yesID, noID, callerIsYesBox: false, idx })}
                    ></input>
                    <label htmlFor={noID}>No</label>
                  </Text>
                </Box>
              </Box>
            );
          })}
          {/* show this box once above ones are filled, or if it is filled before unselecting one above*/}
          {allQuestionsAnswered || isBirthdateSet || isGradeLevelSet ? (
            <Box flex-direction="column" align-items="center" id="birthdate-box">
              <Box
                padding="20px 0px 20px 0px"
                height="230px"
                hover-background="--color-lightD1 center center/110% no-repeat"
                transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
                background="--color-lightD1 center center/100% no-repeat"
                width="100%"
              >
                <Text
                  display="inline-block"
                  opacity="0.6"
                  color="--darkL2"
                  text-align="left"
                  font="--base"
                  margin="10px 10px 10px 10px"
                ></Text>
                <Text
                  display="flex"
                  text-align="justify"
                  margin-left="15px"
                  margin-right="15px"
                  justify-content="space-between"
                >
                  <label htmlFor="birthday">Birthday: </label>
                  <input
                    type="date"
                    id="birthday"
                    name="birthday"
                    onChange={() => setIsBirthdateSet(true)}
                  />
                </Text>
              </Box>
            </Box>
          ) : null}
          {/* show this box once above ones are filled, or if it is filled before unselecting one above*/}
          {(allQuestionsAnswered && isBirthdateSet) || isGradeLevelSet ? (
            <Box flex-direction="column" align-items="center" id="gradelevel-box">
              <Box
                padding="20px 0px 20px 0px"
                height="230px"
                hover-background="--color-lightD1 center center/110% no-repeat"
                transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
                background="--color-lightD1 center center/100% no-repeat"
                width="100%"
              >
                <Text
                  display="inline-block"
                  opacity="0.6"
                  color="--darkL2"
                  text-align="left"
                  font="--base"
                  margin="10px 10px 10px 10px"
                ></Text>
                <Text
                  display="flex"
                  text-align="justify"
                  margin-left="15px"
                  margin-right="15px"
                  justify-content="space-between"
                >
                  <label htmlFor="grade_level">Highest grade level: </label>
                  <select name="grade_level" onChange={() => setIsGradeLevelSet(true)}>
                    {educationOptions.map(({ value, text }) => {
                      return <option value={value}>{text}</option>;
                    })}
                  </select>
                </Text>
              </Box>
            </Box>
          ) : null}
        </Box>
        <Button
          width="150px"
          margin="10px auto auto calc(100% - 150px)"
          color="--lightL2"
          transition="opacity .15s ease 0s"
          z-index="5"
          active-box-shadow="none"
          background="--color-primary"
          opacity="1"
          md-font="--base"
          padding="8px 36px 8px 36px"
          letter-spacing="0.5px"
          position="relative"
          hover-box-shadow="none"
          font="--lead"
          hover-opacity=".85"
          focus-box-shadow="none"
          display={!(allQuestionsAnswered && isBirthdateSet && isGradeLevelSet) ? 'none' : 'auto'}
          onClick={() => setIsDecodeTestReady(true)}
        >
          Next
        </Button>
      </Section>
    </Theme>
  );
};

export default Team;
